import React, {useState, useCallback} from "react"
import Navbar from "./Navbar";
import { Outlet, useNavigate } from "react-router-dom"


export default function StudentApp(props) {
  const navigate = useNavigate()
  const [activeAssignment, setActiveAssignment] = useState(null)
  const [title, setTitle] = useState(null)
  const {token, setAuth, setToken} = props

  const authorizedFetch = useCallback(async (URL, method, body=null, headers=null) => {
    if (!token) {
      setAuth(null)
      navigate('/student/login')
    } else {
      const options = {
        headers: {
          ...headers, 
          'Authorization': token,
          'Content-Type': 'application/json',
        }
      }
      if (method && method !== "GET") {
        options.method = method
        if (body) {
          options.body = JSON.stringify(body)
        }
      }
      const res = await fetch(URL, options)
      if (res.status === 401) {
        setAuth(null)
        setToken(null)
        navigate('/student/login')
      } else {
        const data = await res.json()
        return data
      }
    }
  }, [token, navigate, setAuth, setToken])

  return (
    <div>
      <Navbar 
        loggedIn={props.auth?.user_id} setAuth={setAuth} setToken={setToken}
        prefix="/student" title={title} setTitle={setTitle}
      />
      <Outlet context={{activeAssignment, setActiveAssignment, authorizedFetch, setTitle}}/>
    </div>
  )
}