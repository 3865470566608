import React, {useState, useEffect} from "react"
import { Link, useNavigate } from "react-router-dom"
import { decodeToken } from "../utils/auth";
import LoginRow from "../components/LoginRow";

export default function TeacherLogin(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (props.token) {
      navigate('/teacher')
    }
  }, [navigate, props.token])

  async function attemptLogin(e) {
    e.preventDefault()
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/teacher/login`, {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, password})
      })
      const data = await res.json()
      if (data?.error) {
        throw new Error(data.error)
      }
      if (decodeToken(data.token, props.setAuth, props.setToken)) {
        navigate('/teacher')
      }
    } catch (err) {
      console.error(err)
      setErrors(err.message)
    }

  }

  return (
    <div className="login flexCol">
      <h1>Welcome</h1>
      <h3><span style={{color: "rgb(241, 139, 2)"}}>Personalized</span> Learning </h3>
      <form onSubmit={attemptLogin} className="flexCol">
        <LoginRow label="Email" value={email} setFunc={setEmail}/>
        <LoginRow label="Password" value={password} setFunc={setPassword} isPassword={true}/>
        <button type="submit" className="orangeButton popoutButton">Log In</button>
        {errors && <p className="errorMessage">{errors}</p>}
      </form>

      <p className="switchLoginRegister">No account? <Link to="/teacher/register">Register</Link></p>
    </div>
  )
}