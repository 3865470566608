import React, {useState, useEffect} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import ClassRoster from "../components/ClassRoster";
import Graph from "../components/Graph";
import RightTriangle from "../components/RightTriangle";
import Latex from 'react-latex-next'
import parse from 'html-react-parser';

//
// NOTE: like 90% of this code is pulled from the Chat.js file. Maybe refactor at some point
//

export default function ViewChats(props) {
    const navigate = useNavigate();
    const { selectedSession, authorizedFetch } = useOutletContext();
    let [messages, setMessages] = useState([])
    
    useEffect(() => {
        async function fetchChats() {
            const data = await authorizedFetch(
              `${process.env.REACT_APP_BACKEND_URL}/teacher/viewchats/${selectedSession.student_id}`,
            )
            setMessages(data.chats.map(prev => ({contents: prev.contents, fromSelf: prev.role === "user", timestamp: new Date(prev.timestamp)})))
        }

        if (selectedSession) {
            fetchChats()
        } else {
            navigate('/teacher/assignment')
        }
    }, [navigate, authorizedFetch, selectedSession])

    const pastMessagesRef = React.useRef(null);
    useEffect(() => {
      // Scroll to the last message after messages have changed
      if (pastMessagesRef.current) {
        pastMessagesRef.current.scrollTop = pastMessagesRef.current.scrollHeight;
      }
    }, [messages]);
    
    function checkForLatex(message) {
        if (message.includes('$$') || message.includes('\\(')) {
          return <Latex>{message.replaceAll('$$', '$').replaceAll('\\\\', '\\')}</Latex>
        }
        return parse(message)
    }    

    function renderMessageContents(contents) {
        const graphJSON = contents.includes("GRAPHJSON{") && contents.split("GRAPHJSON")[1].split('\n')[0].replaceAll("'", '"')
        const graphProps = graphJSON && JSON.parse(graphJSON)
        //if (graphProps) console.log(graphProps)
        let table = contents.includes("TABLE[[") && contents.split("TABLE[[")[1].split('\n')[0]
        let triangle_visual = contents.includes("TRIANGLEVISUAL{") && contents.split("TRIANGLEVISUAL")[1].split('\n')[0]
        if (graphProps) {
          const arrayProps = ["xdomain", "ydomain", "axisLabels"]
          for (const arrayProp of arrayProps) {
            if (graphProps[arrayProp]) {
              graphProps[arrayProp] = JSON.parse(graphProps[arrayProp])
            }
          }
          let before = contents.split("GRAPHJSON")[0]
          let after = contents.split("GRAPHJSON")[1].split('\n').slice(1).join("\n")
          return <div className="message">
            {checkForLatex(before)}
            {<Graph {...graphProps}/>}
            {checkForLatex(after)}
          </div>
        } else if (table) {
          table = JSON.parse('[[' + table)
          let before = contents.split("TABLE[[")[0]
          let after = contents.split("TABLE[[")[1].split('\n').slice(1).join("\n")
          return <div className="message">
            {checkForLatex(before)}
            <table><tbody>
              <tr>
                <td className="pointTableCell">X values</td>
                {Array(table[0].length).fill().map((_, i) => <td key={i} className='pointTableCell'>
                  {table[0][i]}
                </td>)}
              </tr>
              <tr>
                <td className="pointTableCell">Y values</td>
                {Array(table[1].length).fill().map((_, i) => <td key={i} className='pointTableCell'>
                  {table[1][i]}
                </td>)}
              </tr>
            </tbody></table> 
            {checkForLatex(after)}
          </div>
        } else if (triangle_visual) {
          let before = contents.split("TRIANGLEVISUAL{")[0]
          let after = contents.split("TRIANGLEVISUAL{")[1].split('\n').slice(1).join("\n")
          return <div className="message">
            {checkForLatex(before)}
            {<RightTriangle/>}
            {checkForLatex(after)}
          </div>
        }
        return <div className="message">
          {checkForLatex(contents)}
        </div>
      }
    
      return (
        <div className="page chat">
          <button onClick={() => navigate("/teacher/assignment")} style={{ marginBottom: "10px" }}>Return to Assignment</button>
          <div className="assignmentHeader">
            <h2 className="pageTitle">{selectedSession?.name}</h2>
            {/*<p>Questions done: {activeAssignment?.questions_done} out of {activeAssignment?.total_questions}</p>*/}
          </div>
          <div className="messageWrapper">
            <div className="pastMessages" ref={pastMessagesRef}>
            <div id='scroll-anchor'></div>
            {messages.map((message, i ) => <div key={i}
              className={`messageRow flexRow ${message.fromSelf ? "fromSelf" : "fromServer"}`}
            >
              {renderMessageContents(message.contents)}
              {/*<p>{message.timestamp?.toString()}</p>*/}
            </div>)}
            </div>
          </div>
        </div>
      )
    // <div>
    //     <button onClick={() => navigate("/teacher/assignment")}>Return to Assignment</button>        
    // </div>        
    
}