import { Link, useLocation} from "react-router-dom";

export default function Navbar(props) {
  const location = useLocation();
  const page = location.pathname
  return (
    <nav id="navbar">
      <Link to={`${props.loggedIn ? props.prefix : "/"}`} 
        className="logoButton popoutButton orangeButton"
        onClick={() => {
          props.setTitle(null)
        }}
      >
        EdEngage
      </Link>
      
      {props.title}

      {props.loggedIn && page === props.prefix && <Link 
        to={`${props.prefix}/login`} 
        className="navButton popoutButton orangeButton" 
        onClick={() => {
          if (props.loggedIn) {
            props.setAuth(null);
            props.setToken(null);
            localStorage.removeItem("edengage_jwtToken");
          }
        }}
      >
        {props.loggedIn ? "Log Out" : "Login"}
      </Link>}
    </nav>
  );
}