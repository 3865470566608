import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import Latex from 'react-latex-next';


/*const BACKEND_URL = 'https://edengage-backend.azurewebsites.net';*/


const studentSVG = <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M80.0583 5.95239L14.8322 6.05223C9.91993 6.05975 5.94384 10.048 5.95136 14.9603L6.01489 56.4678C6.02241 61.38 10.0107 65.3561 14.923 65.3486L80.149 65.2488C85.0613 65.2412 89.0374 61.253 89.0299 56.3407L88.9663 14.8332C88.9588 9.92095 84.9705 5.94487 80.0583 5.95239Z" fill="#1565C0"/>
  <path d="M41.8247 33.1395C43.0928 31.9042 43.9635 30.3186 44.3253 28.5857C44.6871 26.8528 44.5235 25.0513 43.8555 23.4118C43.1875 21.7724 42.0456 20.3696 40.5757 19.3829C39.1059 18.3962 37.375 17.8706 35.6047 17.8733C33.8344 17.876 32.1052 18.407 30.6384 19.3981C29.1715 20.3893 28.0339 21.7956 27.3709 23.4371C26.708 25.0786 26.5499 26.8805 26.917 28.6123C27.2841 30.3442 28.1596 31.9271 29.4315 33.1584C22.8225 34.9475 17.8524 42.0412 17.8654 50.5207C17.8666 51.3071 18.1801 52.0607 18.737 52.6159C19.2938 53.171 20.0484 53.4823 20.8348 53.4811C21.6211 53.4799 22.3748 53.1663 22.9299 52.6095C23.4851 52.0526 23.7963 51.298 23.7951 50.5116C23.7851 43.9593 27.7796 38.646 32.6716 38.6385L38.6014 38.6294C43.4934 38.6219 47.5041 43.9229 47.5142 50.4753C47.5154 51.2617 47.8289 52.0153 48.3858 52.5705C48.9426 53.1257 49.6973 53.4369 50.4836 53.4357C51.2699 53.4345 52.0236 53.1209 52.5788 52.5641C53.1339 52.0072 53.4451 51.2526 53.4439 50.4663C53.431 41.9867 48.4391 34.9083 41.8247 33.1395ZM35.6138 23.8096C36.2002 23.8087 36.7737 23.9817 37.2618 24.3067C37.7498 24.6318 38.1306 25.0942 38.3558 25.6356C38.581 26.1771 38.6407 26.7731 38.5271 27.3484C38.4136 27.9237 38.1321 28.4524 37.718 28.8677C37.304 29.283 36.7762 29.5662 36.2012 29.6815C35.6263 29.7968 35.03 29.739 34.4879 29.5154C33.9458 29.2918 33.4822 28.9125 33.1557 28.4254C32.8291 27.9383 32.6544 27.3654 32.6535 26.779C32.6523 25.9927 32.9635 25.238 33.5186 24.6812C34.0738 24.1243 34.8275 23.8108 35.6138 23.8096Z" fill="#E64A19"/>
  <path d="M74.1564 23.7507L65.2618 23.7644C64.4755 23.7656 63.7209 23.4543 63.164 22.8992C62.6071 22.344 62.2936 21.5903 62.2924 20.804C62.2912 20.0177 62.6024 19.2631 63.1576 18.7062C63.7127 18.1493 64.4664 17.8358 65.2527 17.8346L74.1474 17.821C74.9337 17.8198 75.6883 18.131 76.2452 18.6862C76.8021 19.2413 77.1156 19.995 77.1168 20.7813C77.118 21.5677 76.8068 22.3223 76.2516 22.8791C75.6964 23.436 74.9428 23.7495 74.1564 23.7507Z" fill="#E0E0E0"/>
  <path d="M74.1749 35.6099L56.3856 35.6372C55.5992 35.6384 54.8446 35.3272 54.2878 34.772C53.7309 34.2168 53.4174 33.4632 53.4162 32.6768C53.415 31.8905 53.7262 31.1359 54.2813 30.579C54.8365 30.0221 55.5902 29.7086 56.3765 29.7074L74.1658 29.6802C74.9521 29.679 75.7067 29.9902 76.2636 30.5454C76.8205 31.1005 77.134 31.8542 77.1352 32.6405C77.1364 33.4269 76.8252 34.1815 76.27 34.7383C75.7149 35.2952 74.9612 35.6087 74.1749 35.6099Z" fill="#E0E0E0"/>
  <path d="M74.1928 47.4693L65.2982 47.4829C64.5118 47.4841 63.7572 47.1729 63.2004 46.6177C62.6435 46.0625 62.33 45.3089 62.3288 44.5225C62.3276 43.7362 62.6388 42.9816 63.1939 42.4247C63.7491 41.8678 64.5028 41.5543 65.2891 41.5531L74.1837 41.5395C74.9701 41.5383 75.7247 41.8495 76.2816 42.4047C76.8384 42.9598 77.152 43.7135 77.1532 44.4998C77.1544 45.2862 76.8432 46.0408 76.288 46.5976C75.7328 47.1545 74.9792 47.468 74.1928 47.4693Z" fill="#E0E0E0"/>
  <path d="M80.1584 71.1785L14.9323 71.2783C12.5733 71.2819 10.3124 72.2225 8.64696 73.8931C6.98148 75.5637 6.04785 77.8274 6.05146 80.1864C6.05507 82.5454 6.99562 84.8063 8.66621 86.4717C10.3368 88.1372 12.6006 89.0709 14.9595 89.0672L80.1856 88.9674C82.5445 88.9638 84.8055 88.0232 86.4709 86.3526C88.1364 84.6821 89.07 82.4183 89.0664 80.0593C89.0628 77.7004 88.1223 75.4395 86.4517 73.774C84.7811 72.1085 82.5173 71.1749 80.1584 71.1785Z" fill="#37474F"/>
  <path d="M14.8324 6.05223C12.4734 6.05584 10.2124 6.99641 8.54691 8.66703C6.8814 10.3377 5.94775 12.6015 5.95136 14.9605L6.0149 56.4688C6.01851 58.8278 6.95908 61.0888 8.6297 62.7543C10.3003 64.4198 12.5641 65.3534 14.9232 65.3498L47.5369 65.2999L47.4461 6.00231L14.8324 6.05223Z" fill="#1E88E5"/>
  <path d="M41.8247 33.1395C43.0928 31.9042 43.9635 30.3186 44.3253 28.5857C44.6871 26.8528 44.5235 25.0513 43.8555 23.4118C43.1875 21.7724 42.0456 20.3696 40.5757 19.3829C39.1059 18.3962 37.375 17.8706 35.6047 17.8733C33.8344 17.876 32.1052 18.407 30.6384 19.3981C29.1715 20.3893 28.0339 21.7956 27.3709 23.4371C26.708 25.0786 26.5499 26.8805 26.917 28.6123C27.2841 30.3442 28.1596 31.9271 29.4315 33.1584C22.8225 34.9475 17.8524 42.0412 17.8654 50.5207C17.8666 51.3071 18.1801 52.0607 18.737 52.6159C19.2938 53.171 20.0484 53.4823 20.8348 53.4811C21.6211 53.4799 22.3748 53.1663 22.9299 52.6095C23.4851 52.0526 23.7963 51.298 23.7951 50.5116C23.7851 43.9593 27.7796 38.646 32.6716 38.6385L38.6014 38.6294C43.4934 38.6219 47.5041 43.9229 47.5142 50.4753L47.4925 36.3329C45.8871 34.8287 43.943 33.7333 41.8247 33.1395ZM35.6229 29.7393C35.0365 29.7402 34.463 29.5672 33.9749 29.2422C33.4868 28.9171 33.1061 28.4547 32.8809 27.9133C32.6557 27.3718 32.596 26.7758 32.7095 26.2005C32.8231 25.6252 33.1046 25.0965 33.5186 24.6812C33.9327 24.2659 34.4605 23.9827 35.0355 23.8674C35.6104 23.7521 36.2067 23.8099 36.7488 24.0335C37.2909 24.2571 37.7545 24.6364 38.081 25.1235C38.4076 25.6105 38.5823 26.1835 38.5832 26.7699C38.5844 27.5562 38.2732 28.3109 37.718 28.8677C37.1629 29.4246 36.4092 29.7381 35.6229 29.7393Z" fill="#FF7043"/>
  <path d="M14.9325 71.2783C12.5735 71.2819 10.3125 72.2225 8.64701 73.8931C6.98149 75.5638 6.04785 77.8276 6.05146 80.1866C6.05507 82.5456 6.99564 84.8065 8.66627 86.4721C10.3369 88.1376 12.6007 89.0712 14.9597 89.0676L47.5734 89.0177L47.5462 71.2284L14.9325 71.2783Z" fill="#455A64"/>
  <path d="M80.0583 5.95239L14.8322 6.05223C12.4732 6.05584 10.2123 6.99639 8.54686 8.66698C6.88138 10.3376 5.94775 12.6013 5.95136 14.9603L6.01489 56.4678C6.0185 58.8267 6.95906 61.0876 8.62965 62.7531C10.3002 64.4186 12.564 65.3522 14.923 65.3486L80.149 65.2488C82.508 65.2451 84.7689 64.3046 86.4344 62.634C88.0999 60.9634 89.0335 58.6996 89.0299 56.3407L88.9663 14.8332C88.9627 12.4743 88.0222 10.2134 86.3516 8.54788C84.681 6.8824 82.4172 5.94878 80.0583 5.95239ZM83.1002 56.3498C83.1014 57.1361 82.7902 57.8907 82.2351 58.4475C81.6799 59.0044 80.9263 59.3179 80.1399 59.3191L14.9139 59.419C14.1276 59.4202 13.373 59.109 12.8161 58.5538C12.2593 57.9986 11.9457 57.245 11.9445 56.4587L11.881 14.9512C11.8798 14.1649 12.191 13.4103 12.7462 12.8534C13.3013 12.2966 14.055 11.9831 14.8413 11.9819L80.0673 11.882C80.8537 11.8808 81.6082 12.192 82.1651 12.7472C82.722 13.3023 83.0355 14.056 83.0367 14.8423L83.1002 56.3498Z" fill="#263238"/>
  <path d="M80.1584 71.1785L14.9323 71.2783C12.5733 71.2819 10.3124 72.2225 8.64696 73.8931C6.98148 75.5637 6.04785 77.8274 6.05146 80.1864C6.05507 82.5454 6.99562 84.8063 8.66621 86.4717C10.3368 88.1372 12.6006 89.0709 14.9595 89.0672L80.1856 88.9674C82.5445 88.9638 84.8055 88.0232 86.4709 86.3526C88.1364 84.6821 89.07 82.4183 89.0664 80.0593C89.0628 77.7004 88.1223 75.4395 86.4517 73.774C84.7811 72.1085 82.5173 71.1749 80.1584 71.1785ZM80.1765 83.0378L14.9505 83.1376C14.1641 83.1388 13.4095 82.8276 12.8527 82.2724C12.2958 81.7173 11.9823 80.9636 11.9811 80.1773C11.9799 79.391 12.2911 78.6364 12.8463 78.0796C13.4014 77.5227 14.1551 77.2092 14.9414 77.208L80.1674 77.1081C80.9538 77.1069 81.7083 77.4181 82.2652 77.9733C82.8221 78.5285 83.1356 79.2821 83.1368 80.0684C83.138 80.8547 82.8268 81.6093 82.2716 82.1662C81.7165 82.723 80.9628 83.0366 80.1765 83.0378Z" fill="#263238"/>
</svg>

const teacherSVG = <svg width="91" height="90" viewBox="0 0 91 90" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M29.1839 77.4586L20.7979 77.5935C20.0565 77.6054 19.3408 77.3283 18.8084 76.8231C18.2759 76.3179 17.9701 75.626 17.9585 74.8996C17.9468 74.1732 18.2301 73.4718 18.746 72.9498C19.262 72.4277 19.9683 72.1277 20.7097 72.1158L26.9433 72.0155L28.9935 65.6267C29.2351 64.9465 29.7403 64.3867 30.3998 64.0685C31.0592 63.7502 31.8199 63.6991 32.5171 63.9262C33.2197 64.1462 33.8049 64.6293 34.1452 65.2704C34.4854 65.9116 34.5532 66.6586 34.3337 67.3488L31.6706 75.6104C31.4904 76.1256 31.1575 76.5766 30.7146 76.9058C30.2717 77.235 29.7388 77.4275 29.1839 77.4586Z" fill="#FF8F00"/>
  <path d="M18.0463 80.3773C16.9405 80.3951 15.8545 80.0912 14.9254 79.5041C13.9963 78.917 13.266 78.073 12.8267 77.0789C12.3875 76.0848 12.2591 74.9852 12.4577 73.9191C12.6563 72.8531 13.173 71.8685 13.9426 71.0898C14.7121 70.3112 15.6999 69.7734 16.781 69.5446C17.8621 69.3158 18.9879 69.4062 20.0161 69.8044C21.0444 70.2025 21.9288 70.8906 22.5576 71.7815C23.1864 72.6724 23.5314 73.7262 23.5488 74.8096C23.5722 76.2624 23.0056 77.6651 21.9737 78.7093C20.9417 79.7534 19.529 80.3534 18.0463 80.3773Z" fill="#1565C0"/>
  <path d="M25.507 22.7262C25.1371 22.7306 24.7701 22.6631 24.4271 22.5276C24.0842 22.392 23.7721 22.1911 23.5091 21.9365L18.6821 17.3569L14.2375 17.4284C13.4961 17.4403 12.7805 17.1632 12.248 16.658C11.7155 16.1528 11.4098 15.4609 11.3981 14.7345C11.3864 14.0081 11.6697 13.3067 12.1856 12.7846C12.7016 12.2626 13.408 11.9626 14.1493 11.9506L19.74 11.8607C20.1079 11.8527 20.4737 11.9159 20.8164 12.0467C21.1592 12.1775 21.4722 12.3734 21.7375 12.623L27.4163 18.0108C27.8167 18.3875 28.0934 18.8726 28.2112 19.4043C28.329 19.9359 28.2826 20.49 28.0777 20.9958C27.8729 21.5017 27.519 21.9364 27.0612 22.2446C26.6033 22.5528 26.0623 22.7204 25.507 22.7262Z" fill="#FF8F00"/>
  <path d="M11.4867 20.2126C10.381 20.2303 9.2949 19.9265 8.36583 19.3394C7.43676 18.7523 6.70642 17.9083 6.26717 16.9142C5.82792 15.9201 5.69948 14.8205 5.8981 13.7544C6.09672 12.6884 6.61347 11.7038 7.38302 10.9251C8.15256 10.1464 9.14033 9.60871 10.2214 9.37989C11.3025 9.15108 12.4283 9.24147 13.4566 9.63963C14.4848 10.0378 15.3693 10.7258 15.9981 11.6168C16.6269 12.5077 16.9718 13.5615 16.9892 14.6449C17.0126 16.0976 16.446 17.5004 15.4141 18.5446C14.3822 19.5887 12.9694 20.1887 11.4867 20.2126Z" fill="#1565C0"/>
  <path d="M61.6629 19.4052L67.2535 19.3152L69.9607 13.7925L75.5514 13.7025" fill="#F57C00"/>
  <path d="M67.4373 22.0517L61.8466 22.1417C61.1053 22.1536 60.3896 21.8765 59.8571 21.3713C59.3246 20.8661 59.0189 20.1742 59.0072 19.4478C58.9955 18.7214 59.2788 18.02 59.7948 17.4979C60.3108 16.9759 61.0171 16.6759 61.7585 16.6639L65.6161 16.6019L67.5927 12.5977C67.8178 12.1422 68.1671 11.7567 68.6019 11.4837C69.0368 11.2108 69.5402 11.0611 70.0564 11.0513L75.647 10.9613C76.3884 10.9494 77.1041 11.2265 77.6365 11.7317C78.169 12.2369 78.4748 12.9288 78.4864 13.6552C78.4981 14.3816 78.2148 15.0829 77.6989 15.605C77.1829 16.1271 76.4766 16.4271 75.7352 16.439L71.7378 16.5033L69.7887 20.4797C69.5761 20.9239 69.2458 21.3041 68.8322 21.5806C68.4186 21.8571 67.9368 22.0198 67.4373 22.0517Z" fill="#F57C00"/>
  <path d="M78.7216 19.5963C77.6158 19.6141 76.5298 19.3103 75.6007 18.7231C74.6716 18.136 73.9413 17.2921 73.502 16.298C73.0628 15.3038 72.9343 14.2042 73.133 13.1382C73.3316 12.0721 73.8483 11.0875 74.6179 10.3089C75.3874 9.53021 76.3752 8.99248 77.4563 8.76367C78.5374 8.53486 79.6632 8.62525 80.6914 9.02341C81.7197 9.42156 82.6041 10.1096 83.2329 11.0005C83.8617 11.8915 84.2067 12.9452 84.2241 14.0286C84.2475 15.4814 83.6809 16.8842 82.649 17.9283C81.617 18.9725 80.2043 19.5725 78.7216 19.5963Z" fill="#1565C0"/>
  <path d="M80.1175 68.0917L68.7598 57.3161L65.1375 52.8815C64.8853 52.5935 64.5748 52.36 64.2256 52.1958C63.8764 52.0317 63.4961 51.9404 63.1089 51.9279C62.7241 51.9135 62.3403 51.9772 61.9817 52.115C61.623 52.2528 61.2973 52.4616 61.0248 52.7285L52.3019 61.4712C52.0419 61.7291 51.8366 62.0349 51.6981 62.3711C51.5595 62.7072 51.4903 63.0668 51.4946 63.4292C51.4983 63.7897 51.5746 64.1459 51.7191 64.4774C51.8635 64.8088 52.0734 65.109 52.3366 65.3608L68.0398 80.3401C69.6358 81.8562 71.7814 82.6888 74.0048 82.655C76.2281 82.6173 78.3457 81.7161 79.8921 80.1494L80.3598 79.6762C81.8894 78.1044 82.7225 76.0039 82.6771 73.8334C82.6317 71.6628 81.7115 69.5986 80.1175 68.0917Z" fill="#304046"/>
  <path d="M45.7281 71.7132C62.7102 71.4399 76.2598 57.7298 75.992 41.0909C75.7243 24.4519 61.7406 11.1849 44.7585 11.4581C27.7764 11.7314 14.2268 25.4415 14.4946 42.0805C14.7623 58.7194 28.746 71.9865 45.7281 71.7132Z" fill="#01579B"/>
  <path d="M45.5521 60.7579C56.3588 60.584 64.9813 51.8594 64.8109 41.2709C64.6406 30.6825 55.7418 22.2398 44.9351 22.4137C34.1283 22.5876 25.5058 31.3122 25.6762 41.9007C25.8466 52.4891 34.7453 60.9318 45.5521 60.7579Z" fill="#BBDEFB"/>
  <path d="M29.1875 77.6778L20.8015 77.8127C20.0601 77.8247 19.3444 77.5476 18.8119 77.0424C18.2795 76.5372 17.9737 75.8453 17.962 75.1189C17.9504 74.3925 18.2337 73.6911 18.7496 73.169C19.2656 72.6469 19.9719 72.3469 20.7133 72.335L27.0867 72.2325L29.1368 65.8437C29.363 65.1498 29.8611 64.5721 30.5217 64.2378C30.8488 64.0723 31.2059 63.9715 31.5726 63.9411C31.9394 63.9108 32.3086 63.9516 32.6592 64.061C33.0097 64.1705 33.3348 64.3466 33.6159 64.5792C33.8969 64.8119 34.1284 65.0965 34.2972 65.4169C34.4659 65.7372 34.5686 66.0871 34.5993 66.4464C34.63 66.8057 34.5882 67.1675 34.4762 67.5111L31.8131 75.7726C31.6343 76.3197 31.2846 76.7979 30.8135 77.1398C30.3423 77.4816 29.7735 77.6699 29.1875 77.6778Z" fill="#FF8F00"/>
  <path d="M18.0497 80.5963C16.944 80.6141 15.8579 80.3102 14.9288 79.7231C13.9998 79.136 13.2694 78.292 12.8302 77.2979C12.3909 76.3038 12.2625 75.2042 12.4611 74.1381C12.6597 73.0721 13.1765 72.0875 13.946 71.3088C14.7155 70.5302 15.7033 69.9924 16.7844 69.7636C17.8655 69.5348 18.9913 69.6252 20.0196 70.0234C21.0478 70.4215 21.9322 71.1096 22.561 72.0005C23.1899 72.8914 23.5348 73.9452 23.5522 75.0286C23.5756 76.4814 23.009 77.8841 21.9771 78.9283C20.9452 79.9724 19.5324 80.5724 18.0497 80.5963Z" fill="#0277BD"/>
  <path d="M25.5104 22.9452C25.1426 22.9532 24.7768 22.89 24.434 22.7592C24.0912 22.6283 23.7782 22.4325 23.5129 22.1829L18.6855 17.5759L14.2409 17.6474C13.4995 17.6593 12.7839 17.3822 12.2514 16.877C11.7189 16.3718 11.4132 15.6799 11.4015 14.9535C11.3898 14.2271 11.6731 13.5257 12.1891 13.0036C12.705 12.4816 13.4114 12.1816 14.1528 12.1696L19.7434 12.0797C20.1113 12.0717 20.4771 12.1349 20.8199 12.2657C21.1626 12.3965 21.4756 12.5924 21.7409 12.842L27.4197 18.2298C27.6858 18.4802 27.8987 18.7798 28.046 19.1112C28.1932 19.4427 28.2721 19.7995 28.2779 20.1611C28.2837 20.5226 28.2164 20.8818 28.0799 21.2178C27.9433 21.5539 27.7402 21.8601 27.4823 22.119C27.2252 22.377 26.9187 22.5828 26.5803 22.7246C26.2419 22.8664 25.8783 22.9414 25.5104 22.9452Z" fill="#FF8F00"/>
  <path d="M11.4901 20.4313C10.3844 20.4491 9.29832 20.1452 8.36925 19.5581C7.44018 18.971 6.70984 18.127 6.27059 17.1329C5.83134 16.1388 5.7029 15.0392 5.90152 13.9732C6.10014 12.9071 6.61689 11.9225 7.38644 11.1438C8.15598 10.3652 9.14375 9.82746 10.2248 9.59865C11.3059 9.36984 12.4318 9.46022 13.46 9.85838C14.4882 10.2565 15.3727 10.9446 16.0015 11.8355C16.6303 12.7264 16.9752 13.7802 16.9926 14.8636C17.016 16.3164 16.4494 17.7192 15.4175 18.7633C14.3856 19.8074 12.9729 20.4074 11.4901 20.4313Z" fill="#0277BD"/>
  <path d="M14.6376 42.2973C14.7662 50.2876 18.1291 57.8986 23.9865 63.4558C29.844 69.013 37.7161 72.0613 45.8712 71.93L44.9016 11.675C36.7465 11.8062 28.9765 15.1062 23.3009 20.849C17.6253 26.5918 14.5091 34.307 14.6376 42.2973Z" fill="#0277BD"/>
  <path d="M25.8193 42.1176C25.9011 47.2024 28.0411 52.0457 31.7686 55.5821C35.496 59.1185 40.5055 61.0583 45.6951 60.9748L45.0781 22.6307C39.8885 22.7142 34.944 24.8142 31.3323 28.4687C27.7205 32.1232 25.7374 37.0329 25.8193 42.1176Z" fill="#E3F2FD"/>
  <path d="M80.1171 68.0914L70.4325 58.7683C74.1981 53.6155 76.194 47.4238 76.1316 41.0886C76.0574 34.6246 73.8731 28.3551 69.8984 23.198C65.9238 18.041 60.3676 14.2672 54.0435 12.4294C47.7194 10.5915 40.9595 10.7861 34.7537 12.9847C28.548 15.1832 23.2223 19.2702 19.5564 24.6473C15.8906 30.0243 14.0772 36.4089 14.3818 42.8662C14.6864 49.3235 17.093 55.5142 21.2492 60.532C25.4054 65.5498 31.0928 69.131 37.4787 70.7513C43.8646 72.3715 50.6136 71.9457 56.7373 69.5361L68.095 80.3116C68.8886 81.0655 69.8267 81.6581 70.8552 82.0553C71.8836 82.4525 72.9823 82.6465 74.0878 82.626C75.1935 82.6109 76.2853 82.3817 77.3005 81.9516C78.3157 81.5215 79.2342 80.8991 80.0031 80.12L80.4708 79.6468C81.977 78.0623 82.7848 75.9599 82.7185 73.7963C82.6523 71.6327 81.7175 69.5826 80.1171 68.0914ZM20.2248 41.9882C20.1464 37.1129 21.5452 32.3234 24.2444 28.2253C26.9435 24.1271 30.8218 20.9045 35.3889 18.9648C39.9559 17.0252 45.0065 16.4556 49.9019 17.3282C54.7974 18.2008 59.3179 20.4763 62.8918 23.8671C66.4657 27.2578 68.9325 31.6114 69.9801 36.3774C71.0278 41.1433 70.6094 46.1076 68.7777 50.6424C66.946 55.1772 63.7834 59.0789 59.6898 61.854C55.5961 64.6291 50.7553 66.1531 45.7795 66.2332C39.1072 66.3405 32.6664 63.8465 27.8739 59.2997C23.0815 54.7528 20.33 48.5257 20.2248 41.9882ZM76.2436 75.852L75.7484 76.3531C75.4913 76.6111 75.1848 76.8169 74.8464 76.9587C74.508 77.1005 74.1444 77.1754 73.7765 77.1793C73.4087 77.1873 73.0429 77.1241 72.7001 76.9932C72.3573 76.8624 72.0443 76.6666 71.779 76.4169L61.8393 66.8788C63.6278 65.7533 65.2934 64.4505 66.8097 62.9908L76.1819 72.0176C76.7024 72.5213 77.0003 73.205 77.0119 73.9219C77.0234 74.6388 76.7476 75.3318 76.2436 75.852Z" fill="#263238"/>
</svg>

const importedLanding = <div id="importedLanding">
  <section className="hero">
    <div className="container">
      <h1>Engage Your Students with Personalized Learning</h1>
      <p style={{marginBlockStart: "1em",
      marginBlockEnd: "1em",
      marginInlineStart: 0,
      marginInlineEnd: 0,
      }}>Interactive AI chat-based activities designed to boost confidence and understanding.</p>
      <br/>
      <a href="#demo" className="cta-button">See It in Action</a>
    </div>
  </section>
  <section className="container">
    <div className="quote-section">
      <p>“I struggle at math and EdEngage helped me understand the problem and figure it out.”</p>
      <span className="quote-author">- 8th grade student</span>
    </div>
  </section>
  <section id="about" className="about">
    <div className="container">
      <h2>What is EdEngage</h2>
      <p>EdEngage offers a unique K-12 math platform that provides interactive, chat-based learning experiences tailored to each student's needs. Teachers can assign homework or in-class activities through EdEngage, knowing that students will be supported to do their best.</p>
    </div>
  </section>

  <section className="container">
    <div className="chat-container">
      <div className="assistant-message-wrapper">
        <div className="chat-message assistant-message">
          <p><strong>Problem:</strong> Solve for x: <Latex>{"$ \\frac{x+2}{4} + 3 = 23 $"}</Latex></p>
        </div>
      </div>

      <div className="user-message-wrapper">
        <div className="chat-message user-message">
          <p>Where do I even start?!</p>
        </div>
      </div>

      <div className="assistant-message-wrapper">
        <div className="chat-message assistant-message">
          <p>That's okay! Let's break it down step by step. First, try to simplify the equation by getting rid of the 3 on the left side. What operation would you need to perform on both sides of the equation to achieve this?</p>
        </div>
      </div>

      <div className="user-message-wrapper">
        <div className="chat-message user-message">
          <p>Subtract it?</p>
        </div>
      </div>

      <div className="assistant-message-wrapper">
        <div className="chat-message assistant-message">
          <p>That's correct! You would subtract 3 from both sides of the equation to simplify it. Can you go ahead and do that, then tell me what the new equation looks like?</p>
        </div>
      </div>

      <div className="user-message-wrapper">
        <div className="chat-message user-message">
          <p><Latex>{"$ \\frac{x+2}{4} = 20 $"}</Latex></p>
        </div>
      </div>

      <div className="assistant-message-wrapper">
        <div className="chat-message assistant-message">
          <p>That works! What's next?</p>
        </div>
      </div>
    </div>
  </section>


  <section>
    <div className="container">
      <h2>Why EdEngage</h2>
      <p>EdEngage makes learning easy, even for disengaged students. Students get the support they need to fill their knowledge gap right in the platform, and our unique approach to problem solving makes learning easier for students than cheating.</p>
    </div>
  </section>
  <section id="features" className="benefits">
    <div className="container">
      <ul>
        <li>Real-time feedback to strengthen understanding</li>
        <li>AI-powered coaching that builds student confidence</li>
        <li>Better cheating prevention and detection</li>
        <li>Powerful insights about your class's knowledge gaps</li>
      </ul>
    </div>
  </section>
  <section className="container">
    <div className="quote-section">
      <p>“EdEngage taught me in steps and I could follow along easily”</p>
      <span className="quote-author">- 8th grade student</span>
    </div>
  </section>
  <section id="demo" className="testimonials">
    <div className="container">
      <h2>See EdEngage in Action</h2>
      <p>Fill out this form to schedule a demo.</p>
    </div>
  </section>
  <section>
    <div className="container" style={{textAlign: "center"}}>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc8Clff02k3FYLCetMQWqdmtJw-FJXyIm3HXtQPJbj5ABWF5A/viewform?embedded=true" style={{width: "100%"}} height="1200" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
    </div>
  </section>
  <footer>
    <div className="container">
      <p>&copy; 2024 EdEngage. All rights reserved.</p>
    </div>
  </footer>
</div>

export default function Home(props) {
  const [backendMessage, setBackendMessage] = useState(null);
  useEffect(() => {
    document.title = "EdEngage"
    /*
    fetch(BACKEND_URL)
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch');
      }
      return response.json();
    })
    .then(data => {
      setBackendMessage(data.message);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setBackendMessage('Failed to fetch data from the backend');
    }); */
  } ,[])

  return (
    <div>
      <nav id="navbar">
        <button className='logoButton popoutButton orangeButton'>EdEngage</button>
      </nav>
      <div className="landing flexCol">
        <h1>Welcome</h1>
        <h3>Learning <span style={{color: "rgb(241, 139, 2)"}}>Reimagined</span></h3>

        <div className='flexRow' id='landingLinks'>
          <Link to="/student" 
            className="landingLink orangeButton flexCol centerCenter"
            id="studentLink"
          >
            {studentSVG}
            Students
          </Link>
          <Link to="/teacher" 
            className="landingLink orangeButton flexCol centerCenter"
            id="teacherLink"
          >
            {teacherSVG}
            Teachers
          </Link>
        </div>
        {/* prints whether or not Azure backend is running */}
        {/*<p>Backend message: {backendMessage}</p>*/}
        {importedLanding}
      </div>   
    </div>
  )
}